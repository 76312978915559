import { sortBy, isEqual } from 'lodash';

import { convertEpochTime } from 'core/utilities';
import { formatPercentage } from 'core/utilities/formatters';

import {
  formatImageForServer,
  formatCustomFieldsForServer,
  formatAmounts,
  formatMoneyPledgeForServer,
} from 'modules/common';
import {
  formatThermometerWidgetForUi,
  formatWidgetsAndThermometersForServer,
  formatWidgetsForUi,
} from 'modules/common/widgets/tools';
import { formatCustomFieldsForUi } from 'modules/common/custom-fields/custom/custom.tools';
import { formatUpsellGroupForServer } from 'modules/pages/upsells/tools';

import { convertReccuringForServer, convertRecurringForUi } from './recurring.tools';
import { formatUpsellPagesForUi } from '../upsells/tools';
import { CAMPAIGN_NAME_MAP } from '../campaigns/tools';
import { formatUpsellDropdown, formatUpsellFlowDropdown } from 'modules/pages/upsells/format.tools';
import { formatPathwayForServer, formatPathwayForUi } from '../pathways/pathways.tools';
import uuidv4 from 'core/utilities/uuid';
import {
  formatUpsellPathwayForPageUi,
  formatUpsellPathwayForServer,
  NODE_TYPES,
} from '../upsell-pathways/react-flow.tools';

/**
 * updates page data from server to show on ui
 */
export const formatPageForUi = ({ page, profileCandidate, candidate }) => {
  if (!page) return;
  page = JSON.parse(JSON.stringify(page));

  if (page.thermometerCampaign && page.thermometerWidget) {
    const thermometerCampaign = formatThermometerWidgetForUi(
      page.thermometerCampaign,
      page.thermometerWidget,
      page.widgets
    );
    page.widgets = [thermometerCampaign, ...page.widgets] || [thermometerCampaign];
  } else {
    page.widgets = page.widgets || [];
  }
  page.widgets = page.widgets.map(widget => {
    return {
      ...widget,
      _isCardOpen: false,
    };
  });
  page._disabled = page.status === 'ARCHIVED';

  // If we still have the old upsells toggle button in page setup
  // from the booleans given set the server side props here
  if (page.recurringUpsell) {
    page.enableRecurringUpsell = true;
    page.recurringUpsellOptIn = page.recurringUpsell.conversionRate;
  }

  if (page.doubleUpsell) {
    page.enableDoubleUpsell = true;
    page.doubleUpsellOptIn = page.doubleUpsell.conversionRate;
  }

  if (page?.additionalMoneyPledgeCampaigns?.length > 0) {
    page.additionalMoneyPledgeCampaign = page.additionalMoneyPledgeCampaigns[0]?.moneyPledgeCampaign;
  }

  // set defaults if not given
  if (!page.recurringConfirmRadioButtonCallout) page.recurringConfirmRadioButtonCallout = 'Yes, count me in!';
  if (!page.recurringDeclineRadioButtonCallout) page.recurringDeclineRadioButtonCallout = 'No, donate once';

  if (page.widgets) {
    page.widgets = formatWidgetsForUi(page.widgets, page._disabled);
  }

  page.fulfillmentProducts = {};

  page.campaign?.merch_products?.forEach(({ merch_product: product }) => {
    if (!page.fulfillmentProducts[product.fulfillment_house]) {
      page.fulfillmentProducts[product.fulfillment_house] = [];
    }

    if (product.type !== 'ORGANIZATION_MANAGED_PRODUCT') {
      page.fulfillmentProducts[product.fulfillment_house].push(product);
    }
  });

  if (candidate?.campaigns?.total_count) {
    page.campaignsTotalCount = candidate?.campaigns?.total_count;
  }

  page = convertRecurringForUi(page);
  page = convertCandidatesForUi(page);
  page.customFields = formatCustomFieldsForUi(page.customFields);
  page.upsellPageGroup = formatPageUpsellFlowForUi(page.upsellPageGroup, profileCandidate, page._disabled);
  page.upsellGroupsTotalCount = candidate?.upsell_groups?.total_count;
  page.upsellsTotalCount = candidate?.upsells?.total_count;

  // keep track so we know when to show billpay info
  page._conduitsHaveVendors = hasConduitVendors(page.conduitPageCandidates);

  // business/personal/pac donations
  const contributionSetting = profileCandidate.contribution_setting || {};
  page._enable_business_donations = contributionSetting.enable_business_donations || false;
  page._enable_pac_donations = contributionSetting.enable_pac_donations || false;

  page._enableEmployerAddressDetails = profileCandidate.enable_employer_address_fields;
  page._enableEmployerCityStateZip = profileCandidate.enable_employer_city_state_zip_fields;

  if (page.pathway) {
    if (page.pathway.pathway_type === 'PETITION') {
      page.pathway = formatPathwayForUi(page.pathway);
    } else {
      page.pathway = formatUpsellPathwayForPageUi(page.pathway, profileCandidate);
    }
  }
  return page;
};

export const disableUpsellFlow = (upsellPageGroup, profileCandidate) => {
  const isPublicUpsellGroup = upsellPageGroup?.public_upsell_group ?? false;
  const notFlowOwner = profileCandidate.organization_revv_uid !== upsellPageGroup?.upsell_creator_revv_uid;

  // disable editing if a public upsell group (unless current user owns it)
  return isPublicUpsellGroup && notFlowOwner;
};

export const disableUpsell = (upsell, profileCandidate) => {
  const isPublicUpsell = upsell?.upsell_page?.public_upsell || upsell?.public_upsell || false;
  const creatorId = upsell?.upsell_page?.upsell_creator_revv_uid || upsell?.upsell_creator_revv_uid;

  const notFlowOwner = profileCandidate.organization_revv_uid !== creatorId;

  // disable editing if a public upsell (unless current user owns it)
  return isPublicUpsell && notFlowOwner;
};

export const formatPageUpsellFlowForUi = (upsellPageGroup, profileCandidate, disabled) => {
  if (!upsellPageGroup) return upsellPageGroup;

  const disabledUpsellFlow = disableUpsellFlow(upsellPageGroup, profileCandidate) || disabled;
  let formattedUpsellPageGroup = { ...upsellPageGroup, _isDisabled: disabledUpsellFlow };

  if (formattedUpsellPageGroup.upsell_pages) {
    formattedUpsellPageGroup.upsell_pages = formattedUpsellPageGroup.upsell_pages.map(upsell =>
      formatUpsellPagesForUi(upsell, disabledUpsellFlow, profileCandidate)
    );
  }

  return formattedUpsellPageGroup;
};

export const formatUpsellPageGroup = upsellPageGroup => {
  const formattedUpsellFlows = upsellPageGroup?.upsellPages?.map(page => {
    return {
      name: page.upsell_page.name,
      revv_uid: page.upsell_page.revv_uid,
      public_upsell: page.upsell_page.public_upsell,
      upsell_creator: page.upsell_page.upsell_creator,
      upsell_creator_revv_uid: page.upsell_page.upsell_creator_revv_uid,
      type: page.upsell_page.type,
    };
  });
  return formattedUpsellFlows;
};

export const updatePageForServer = ({ page, isPetitionPage, isUpsellFlowEdited, removedExistingUpsellGroupData }) => {
  page = JSON.parse(JSON.stringify(page));

  // some props have to be removed before sending to backend
  [
    '__typename',
    'created_at',
    'updated_at',
    'url',
    'recurringUpsell',
    'recurringUpsellOptIn',
    'smsOptinUpsell',
    'smsOptinUpsellOptIn',
    'doubleUpsell',
    'doubleUpsellOptIn',
    'qrCodeUrl',
    'highDollar',
    'pageFees',
    'billPayExists',
    'confirmationPreviewPageUrl',
    'redirectPageRevvUid',
    'enableEmployerDetails',
    'upsellGroupsTotalCount',
    'upsellsTotalCount',

    // temp internal props we dont need
    '_conduitsHaveVendors',
    '_enableEmployerAddressDetails',
    '_enable_business_donations',
    '_enable_pac_donations',
    '_enableEmployerAddressDetails',
    '_enableEmployerCityStateZip',
    '_disabled',
  ].forEach(k => delete page[k]);

  if (page.petitionPageFields) {
    delete page.petitionPageFields.__typename;
  }

  page.initiativeCampaignRevvUid = page.campaign?.revv_uid || page.campaign?.value?.revv_uid;
  delete page.campaign;
  delete page.campaignsTotalCount;
  delete page.fulfillmentProducts;

  // when making an org account, a default page is created that has callToAction
  // set to null but we want it set to empty string instead so check here
  page.callToAction = page.callToAction || '';

  // make sure all amounts have a value
  if (page.pageAmounts?.length)
    page.pageAmounts = page.pageAmounts
      .map(donation => {
        delete donation.__typename;
        return donation;
      })
      .filter(donationAmount => donationAmount.amount);

  page = updateTypeForServer(page);
  page = convertReccuringForServer(page);
  page = updateImagesForServer(page);
  page.pageAmounts = formatAmounts(page.pageAmounts);
  page = formatConduitsForServer({ item: page });
  page = formatConfirmationPage(page);
  page.customFields = formatCustomFieldsForServer(page.customFields);

  if (!isPetitionPage && page.upsellPageGroup && isUpsellFlowEdited) {
    if (page.upsellPageGroup.destroy && page.upsellPageGroup.revv_uid) {
      // No need to format upsell if set to destroy
      return;
    }
    page = formatUpsellGroupForServer(page, removedExistingUpsellGroupData);
  }

  // if this is a petition page or the upsell flow hasnt been edited then we delete upsells
  if (isPetitionPage || (!isUpsellFlowEdited && !page?.upsellPageGroup?.destroy)) {
    delete page.upsellPageGroup;
  }

  page = formatAdditionalMoneyPledge(page);
  page = formatPageMinMaxAmounts(page);
  page = formatMoneyPledgeForServer(page);
  page = formatWidgetsAndThermometersForServer(page);
  delete page.thermometerCampaign;

  if (page.recurringDefaultValue === null) {
    page.recurringDefaultValue = true;
  }

  if (page.pathway) {
    if (page.type === 'PETITION') {
      page.pathway = formatPathwayForServer(page.pathway);
    } else {
      page.pathway = formatUpsellPathwayForServer(page.pathway);
    }
  }

  if (page.pathway_steps) {
    delete page.pathway_steps;
  }

  if (page.pathway_end_steps) {
    delete page.pathway_end_steps;
  }

  if (page.upsellProcessType) {
    delete page.upsellProcessType;
  }
  return page;
};

export const formatAdditionalMoneyPledge = page => {
  const hasPreviousSecondMoneyPledge = page?.additionalMoneyPledgeCampaigns?.length > 0;
  const previousSecondMoneyPledgeId =
    hasPreviousSecondMoneyPledge && page?.additionalMoneyPledgeCampaigns[0]?.moneyPledgeCampaign?.revv_uid;
  const currentSecondMoneyPledge = page?.additionalMoneyPledgeCampaign;
  let isOverRidingOldSecondMoneyPledge =
    hasPreviousSecondMoneyPledge && previousSecondMoneyPledgeId !== currentSecondMoneyPledge.revv_uid;
  let formattedAdditionalMoneyPledge = [];

  if (isOverRidingOldSecondMoneyPledge || (hasPreviousSecondMoneyPledge && !currentSecondMoneyPledge)) {
    formattedAdditionalMoneyPledge.push({
      moneyPledgeCampaignRevvUid: previousSecondMoneyPledgeId,
      destroy: true,
    });
  }
  if (currentSecondMoneyPledge) {
    formattedAdditionalMoneyPledge.push({
      moneyPledgeCampaignRevvUid: currentSecondMoneyPledge.revv_uid,
      position: 1,
    });
  }

  page.additionalMoneyPledgeCampaigns = formattedAdditionalMoneyPledge;
  delete page.additionalMoneyPledgeCampaign;
  return page;
};

export const formatPageMinMaxAmounts = page => {
  page.minimumAmount = page.minimumAmount ? parseFloat(page.minimumAmount, 10) : null;
  page.maximumAmount = page.maximumAmount ? parseFloat(page.maximumAmount, 10) : null;

  return page;
};

export const validActiveTabs = [
  'setup',
  'creative',
  'form_fields',
  'confirmation',
  'upsell',
  'petition_pathways',
  'advanced',
  'widgets',
  'custom_fields',
  'committees',
  'billpay',
];

export const validActiveTabsPetition = [
  'setup',
  'creative',
  'form_fields',
  'confirmation',
  'advanced',
  'petition_pathways',
  'custom_fields',
  'widgets',
  'committees',
];

export const validActiveTabsOrganization = [
  'setup',
  'creative',
  'form_fields',
  'confirmation',
  'advanced',
  'custom_fields',
  'committees',
];

/** if  state_level_splits_active then we dont even show committee tab */
export const validActiveTabsOrganizationSplits = [
  'setup',
  'creative',
  'form_fields',
  'confirmation',
  'advanced',
  'custom_fields',
];

export const pageImages = [
  'logoAssignedImage',
  'mobileBannerAssignedImage',
  'backgroundAssignedImage',
  'ogAssignedImage',
  'twitterAssignedImage',
];

export const getSavePageEventData = (page, isOrganization, isPetitionPage) => {
  // if new and destoryed then backend will enber know about it
  const conduitPageCandidates = (page.conduitPageCandidates || []).filter(c => {
    if (c._isNew) return !c._destroy;
    return true;
  });

  const eventParams = {
    'page id': page.revv_uid,
    type: page.type,
    'created date': convertEpochTime(page.created_at),
    'internal name': page.internalName,
    slug: page.slug,
    title: page.publicTitle,
    'recurring upsell id': page.recurringUpsell?.revv_uid,
    'double upsell id': page.doubleUpsell?.revv_uid,
    layout: page.layout,
    position: page.position,
    'desktop logo id': page.logoAssignedImage?.image?.id,
    'mobile id': page.mobileBannerAssignedImage?.image?.id,
    'background image id': page.backgroundAssignedImage?.image?.id,
    amounts: page.pageAmounts.map(amountObject => amountObject.amount.toString()),
    'mobile field enabled': page.enableMobileField,
    'mobile field required': page.requireMobileField,
    'mobile opt-in enabled': page.enableSmsOptin,
    'shipping information': page.enableShippingDetails,
    'recurring button enabled': page.recurringAvailable,
    'donor choose interval enabled': page.recurringIntervalSelectable,
    'default value': page.recurringDefaultValue,
    'recurring interval': page.recurringInterval,
    'recurring end date': page.recurringEndAt,
    'confirmation custom button enabled': !!page.confirmationCustomLink,
    'facebook slug': page.confirmationFacebookHandle,
    'twitter handle': page.confirmationTwitterHandle,
    language: page.locale,
    status: page.status,
    campaign: page.campaign?.revv_uid,
    'custom field count': page.customFields?.length ?? 0,
    'custom fields types': page.customFields?.map(cf => cf.input_type) ?? '',
    'candidate count': conduitPageCandidates?.length ?? 0,
    candidates: conduitPageCandidates?.map(candidate => candidate.name).join(', '),
  };

  return pageUpsellFlowEventParams(page, eventParams, isOrganization, isPetitionPage);
};

/**
 * adds event props for a page's upsell flow
 */
export const pageUpsellFlowEventParams = (page, eventParams, isOrganization, isPetitionPage) => {
  if (isOrganization) return eventParams;
  if (isPetitionPage) return eventParams;

  eventParams['upsell flow id'] = page.upsellPageGroup?.revv_uid ?? '';
  eventParams['upsell flow name'] = page.upsellPageGroup?.name ?? '';
  eventParams['upsell count'] = page.upsellPageGroup?.upsell_pages?.length ?? 0;
  eventParams['upsell types'] = page.upsellPageGroup?.upsell_pages?.map(upsell => upsell.upsell_page?.type) ?? '';

  return eventParams;
};

/**
 * Check to see if there is an assigned image prop for logo and background
 *   If there is an imageId attribute in either of those, then that means we are adding
 *   or changing the image, so we need to cleanup the data model before saving
 *
 *   If there isn't an imageId attribute, remove the field entirely, otherwise it will fail to save
 */
export const updateImagesForServer = page => {
  page = formatImageForServer(page, 'logoAssignedImage');
  page = formatImageForServer(page, 'mobileBannerAssignedImage');
  page = formatImageForServer(page, 'backgroundAssignedImage');
  page = formatImageForServer(page, 'ogAssignedImage');
  page = formatImageForServer(page, 'twitterAssignedImage');

  return page;
};

/**
 * For New Pages, images that are marked to be destroyed must be set to null for graphql api
 */
export const removeDestroyedImagesForServer = page => {
  pageImages.forEach(imageKey => {
    if (page[imageKey]?.destroy) {
      page[imageKey] = null;
    }
  });
  return page;
};

/**
 * formats conduitPageCandidates and conduitPageOrganizations props for pages and upsells
 * we combine them on formatForUi to show on UI as one list but split them back up here to send to API
 */
export const formatConduitsForServer = ({ item, isTeamPage = false }) => {
  if (!item.conduitPageCandidates) return item;

  // split federal vs state candidates
  let conduitPageCandidates = item.conduitPageCandidates.filter(c => !c._isStateLevel);
  const conduitPageOrganizations = item.conduitPageCandidates.filter(c => c._isStateLevel);

  item.conduitPageOrganizations = conduitPageOrganizations
    .filter(c => (c._isNew ? !c._destroy : true))
    .map((conduitCandidate, index) => {
      const candidate = conduitCandidate.candidate || conduitCandidate.organization;
      const newCandidate = {
        organizationRevvUid: candidate.revv_uid,
        position: index + 1,
      };

      if (!isTeamPage) {
        newCandidate.vendorFees = conduitCandidate.vendorFees
          ? formatCandidateBillpay(conduitCandidate.vendorFees)
          : [];
      }

      if (conduitCandidate._destroy) newCandidate.destroy = true;
      if (!item.splitEvenly && 'defaultPercentage' in conduitCandidate) {
        newCandidate.defaultPercentageAsPercentage = formatPercentage(
          Number(conduitCandidate.defaultPercentage) ?? 0
        ).toString();
      } else {
        // Clear out the default percentage when splitting evenly.
        newCandidate.defaultPercentageAsPercentage = '0';
      }
      return newCandidate;
    });

  // we dont send the whole current candidates just the candidates we are adding/deleting
  item.conduitPageCandidates = conduitPageCandidates
    .filter(c => (c._isNew ? !c._destroy : true))
    .map((conduitCandidate, index) => {
      const candidate = conduitCandidate.candidate || conduitCandidate.organization;
      const newCandidate = {
        conduitCandidateRevvUid: candidate.revv_uid,
        position: index + 1,
      };

      if (!isTeamPage) {
        newCandidate.vendorFees = conduitCandidate.vendorFees
          ? formatCandidateBillpay(conduitCandidate.vendorFees)
          : [];
      }

      if (conduitCandidate._destroy) newCandidate.destroy = true;

      newCandidate.allowDataSharing = 'allowDataSharing' in conduitCandidate ? conduitCandidate.allowDataSharing : true;
      if (!item.splitEvenly && 'defaultPercentage' in conduitCandidate) {
        newCandidate.defaultPercentageAsPercentage = formatPercentage(
          Number(conduitCandidate.defaultPercentage) ?? 0
        ).toString();
      } else {
        // Clear out the default percentage when splitting evenly.
        newCandidate.defaultPercentageAsPercentage = '0';
      }

      return newCandidate;
    });

  return item;
};

// billpay (agency fees) formatting
export const formatCandidateBillpay = vendorFees => {
  return vendorFees
    .filter(c => (c._isNew ? !c._destroy : true))
    .map(vendorFee => {
      const newVendorFee = {
        partnershipId: vendorFee.partnershipId,
        feePercentage: parseFloat(vendorFee.feePercentage),
      };

      if (!vendorFee._isNew) newVendorFee.id = vendorFee.id;
      if (vendorFee._destroy) newVendorFee.destroy = true;

      return newVendorFee;
    });
};
/**
 * add destroy flag so we know which ones need to be destroyed
 * @param {*} page
 */
export const convertCandidatesForUi = (page, disabled = false) => {
  let newPageFormatted = {
    ...page,
    conduitPageCandidates: [...(page.conduitPageCandidates || []), ...(page.conduitPageOrganizations || [])],
  };
  // combine together to make it easier on the ui

  newPageFormatted.conduitPageCandidates = newPageFormatted.conduitPageCandidates.map((conduitCandidate, index) => {
    let newConduitCandidate = { ...conduitCandidate };
    newConduitCandidate.id = index;
    newConduitCandidate._destroy = false;
    newConduitCandidate._disabled = disabled;

    if (!newConduitCandidate.hasOwnProperty('allowDataSharing')) newConduitCandidate.allowDataSharing = true;
    if ('defaultPercentage' in newConduitCandidate) {
      newConduitCandidate.defaultPercentage = formatPercentage(newConduitCandidate.defaultPercentage);
    }

    // keep original copy for summary list
    newConduitCandidate.originalVendorFees = [...(newConduitCandidate.vendorFees || [])];

    const _candidate = newConduitCandidate.candidate || newConduitCandidate.organization;
    newConduitCandidate._isStateLevel = _candidate.revv_uid.startsWith('rv_org_');
    return newConduitCandidate;
  });

  newPageFormatted.conduitPageCandidates = sortBy(newPageFormatted.conduitPageCandidates, 'position', 'desc');
  return newPageFormatted;
};

export const updateTypeForServer = page => {
  // handle page type differences
  if (page.type === 'CONDUIT') {
    page.petitionPageFields = {};
  }

  if (page.type === 'PETITION') {
    delete page.recurringEndAt;
  }

  return page;
};

export const MERCH_TERMS = [
  /\bShirt\b/i,
  /\bHat\b/i,
  /\bMug\b/i,
  /\bSign\b/i,
  /\bGift\b/i,
  /\bTote\b/i,
  /\bOrder\b/i,
  /\bCard\b/i,
  /\bMembership ?card\b/i,
  /\bBumper\b/i,
  /\bSticker\b/i,
  /\bRedeem\b/i,
];

export const verifyPageBeforeSave = ({
  data,
  page,
  originalPage,
  profileCandidate,
  isNew,
  isPetitionPage,

  savePage,
  validationBlock,
  setToast,
  logEvent,
  onChangeFilter,

  validatedMoneyPledgeExpire,
  showMoneyPledgeExpireModal,
  validatedUpsellFlowChange,
  showUpsellFlowChangeModal,
  validatedMerchCampaignMissing,
  showMerchCampaignMissingModal,
  campaigns,
}) => {
  if (validationBlock.current.errors()) {
    setToast({
      message: 'Please correct the errors on this page before saving!',
      isError: true,
    });
    return;
  }

  // if PAC or business enabled then we cant add any orgs to page except our own
  if (page.enableBusinessDonations || page.enablePacDonations) {
    // see if any orgs not the profileCandidate and not destroyed
    // (ie are going to be added to page) - if there is any we cant add them with business/pac enabled.
    const addedConduits = (page.conduitPageCandidates || []).filter(conduitCandidate => {
      const _conduitCandidate = conduitCandidate.candidate || conduitCandidate.organization;
      return _conduitCandidate.revv_uid !== profileCandidate.revv_uid && !conduitCandidate._destory;
    });

    if (addedConduits.length > 0) {
      setToast({
        message:
          'Additional organizations are not able to be added to a page that accepts donations other than Personal.',
        isError: true,
      });
      return;
    }
  }

  // if money pledge is outdated show modal - only on new pages
  if (!validatedMoneyPledgeExpire && isNew && page.moneyPledgeCampaign?.revv_uid) {
    const candidate = data.viewer.candidate || data.viewer.state_level_organization;
    const moneyPledgeDate =
      page.moneyPledgeCampaign?.money_pledge_date || candidate.money_pledges.results[0]?.money_pledge_date;

    if (moneyPledgeDate && moneyPledgeDate * 1000 < new Date().getTime()) {
      logEvent(`page save: view expired money pledge modal`);
      return showMoneyPledgeExpireModal();
    }
  }

  // anything after this line is donation pages validation only
  if (isPetitionPage) return savePage();

  // if we have any of these terms then confirm merch campaign save
  if (!validatedMerchCampaignMissing) {
    const candidate = campaigns?.viewer?.candidate || campaigns?.viewer?.state_level_organization;
    const campaignResults = candidate?.campaigns.results ?? [];

    const hasMerchCampaign =
      !!page.campaign &&
      !!campaignResults
        .filter(campaigns => campaigns.initiative_type === CAMPAIGN_NAME_MAP.MERCHANDISE.new.initiative_type)
        .find(campaign => campaign.revv_uid === page.campaign);

    const actionHasMerch = page.callToAction && !!MERCH_TERMS.find(term => term.test(page.callToAction));

    if (actionHasMerch && !hasMerchCampaign) {
      logEvent(`page save: view merch campaign modal`);
      showMerchCampaignMissingModal();
      return;
    }
  }

  // If the upsell area has changed, confirm the edit and warn the user about the consequences.
  const pageCount = page.upsellPageGroup && page.upsellPageGroup.page_count;

  if (!validatedUpsellFlowChange && pageCount > 0 && !isEqual(page.upsellPageGroup, originalPage.upsellPageGroup)) {
    logEvent(`page save: view confirm edit upsell flow modal`);
    showUpsellFlowChangeModal();
    return;
  }

  // check ACH requirements if it's enabled
  if (page.enableAchPayments) {
    const { passwordProtected, minimumDonation, recurringOptIn, removedUpsellFlow, donationsAmountsUnderHundred } =
      validateAch({
        page,
        originalPage,
      });

    if (
      !passwordProtected ||
      !minimumDonation ||
      !recurringOptIn ||
      !removedUpsellFlow ||
      !donationsAmountsUnderHundred
    ) {
      logEvent(`page save: ACH invalid`);
      setToast({
        message: 'Please fix any requirements to enable ACH payments.',
        isError: true,
      });
      onChangeFilter({ target: { value: 'advanced' } });
      return;
    }
  }

  if (page.pathway) {
    if (page.type === 'PETITION') {
      const { hasStep } = validatePathway({ page });
      if (!hasStep) {
        logEvent('page save: Petition Pathway is missing Steps.');
        setToast({
          message: 'Please add at least one step to the Petition Pathway.',
          isError: true,
        });
        return;
      }
    } else {
      const upsellSteps = page.pathway?.base_step_joins?.filter(
        stepJoin => stepJoin.step.node_type === NODE_TYPES.UPSELL_NODE
      );
      if (upsellSteps?.length < 1) {
        logEvent('page save: Upsell Pathway is missing Upsell.');
        setToast({
          message: 'Please add at least one upsell to the Upsell Pathway.',
          isError: true,
        });
        return;
      }
    }
  }
  return savePage();
};

export const formatPageUpsellFlowTabForUi = data => {
  if (!data.viewer) return { data: { ...data, upsellDropdownOptions: [], upsellGroupsDropdownOptions: [] } };

  const candidate = data.viewer.candidate || data.viewer.state_level_organization;

  const upsellDropdownOptions = formatUpsellDropdown(candidate);
  const upsellGroupsDropdownOptions = formatUpsellFlowDropdown(candidate);

  return {
    data: {
      ...data,
      upsellDropdownOptions,
      upsellGroupsDropdownOptions,
    },
  };
};

/**
 * find the maximum amount we can donation based on which types of donations are enabled
 */
export const calculateMaxDonation = ({ profileCandidate, page }) => {
  const { contribution_setting, maximum_contribution_cents } = profileCandidate;

  const {
    business_donations_maximum_amount_cents = 0,
    pac_donations_maximum_amount_cents = 0,
    enable_business_donations,
    enable_pac_donations,
  } = contribution_setting || {};

  const { enablePersonalDonations, enableBusinessDonations, enablePacDonations } = page;

  let validAmounts = [];
  let maxDonationReasons = [];

  if (enablePersonalDonations && maximum_contribution_cents) {
    validAmounts.push(parseFloat(maximum_contribution_cents / 100, 10));
    maxDonationReasons.push('The amount is greater than the Maximum Personal Donation Amount set on the candidate.');
  }

  if (enable_business_donations && enableBusinessDonations && business_donations_maximum_amount_cents) {
    validAmounts.push(parseFloat(business_donations_maximum_amount_cents / 100, 10));
    maxDonationReasons.push('The amount is greater than the Maximum Business Donation Amount set on the candidate.');
  }

  if (enable_pac_donations && enablePacDonations && pac_donations_maximum_amount_cents) {
    validAmounts.push(parseFloat(pac_donations_maximum_amount_cents / 100, 10));
    maxDonationReasons.push('The amount is greater than the Maximum PAC Donation Amount set on the candidate.');
  }

  if (page.maximumAmount) {
    // if the set max amount is the lowest value then use max amount as the "max" - need to override
    const maxAmtLowest = validAmounts.find(amt => amt < page.maximumAmount);

    if (!maxAmtLowest) {
      validAmounts = [parseFloat(page.maximumAmount, 10)];
      maxDonationReasons = ['The amount is greater than the Maximum Amount set for the page.'];
    } else {
      validAmounts.push(parseFloat(page.maximumAmount, 10));
      maxDonationReasons.push('The amount is greater than the Maximum Amount set for the page.');
    }
  }

  // we want the highest amount allowed
  const maxAmount = Math.max(...validAmounts);
  const maxAmountReasonIndex = validAmounts.indexOf(maxAmount);
  const maxAmountReason = maxDonationReasons[maxAmountReasonIndex];

  return { maxAmountReason: maxAmountReason || '', maxAmount: maxAmount || 0 };
};

export const calculateMinDonation = ({ page }) => {
  const validAmounts = [];
  let minDonationReasons = [];

  if (page.minimumAmount) {
    validAmounts.push(parseFloat(page.minimumAmount, 10));
    minDonationReasons.push('The amount is lower than the Minimum Amount set for the page.');
  }

  if (page.highDollar) {
    validAmounts.push(500);
    minDonationReasons.push('The amount is lower than the $500 Minimum Donation Amount for a High Dollar Page.');
  }

  const minAmount = Math.max(...validAmounts);
  const minAmountReasonIndex = validAmounts.indexOf(minAmount);
  const minAmountReason = minDonationReasons[minAmountReasonIndex];

  return { minAmountReason: minAmountReason || '', minAmount: minAmount || 0 };
};

/**
 * Used to loop through candidates to see if any vendorFees are attached.
 */
export const hasBillPay = (conduitPageCandidates, conduitPageOrganizations, billPayExists) => {
  if (billPayExists) return true;
  if (!conduitPageCandidates || !conduitPageOrganizations) return false;

  const candidates = [...conduitPageCandidates, ...conduitPageOrganizations];
  let billPay = false;

  for (const candidate of candidates) {
    if (candidate.vendorFees?.length > 0) {
      billPay = true;
      break;
    }
  }

  return billPay;
};

export const formatPageListForUi = ({ raised_amounts, results }) => {
  const pagesResults = raised_amounts;

  return results.map(page => {
    page.pageGrossResults = pagesResults.find(amt => amt.revv_uid === page.revv_uid);

    return page;
  });
};

export const fulfillmentHouseMap = {
  ACE: 'ACE',
  ADVOC8: 'ADVOC8',
  MSP_DESIGN: 'MSP Design',
};

export const validateAch = ({ page, originalPage }) => {
  const passwordProtected = !!page.password || originalPage.status === 'PASSWORD_PROTECTED';
  const donationsAmountsUnderHundred = page.pageAmounts.filter(amt => amt.amount < 100 && !amt._destroy).length === 0;
  const minimumDonation = page.minimumAmount >= 100;
  const recurringOptIn = !page.recurringAvailable;
  const removedUpsellFlow =
    (!page.upsellPageGroup || page.upsellPageGroup.destroy) && (!page.pathway || page.pathway.destroy);

  return {
    passwordProtected,
    minimumDonation,
    recurringOptIn,
    removedUpsellFlow,
    donationsAmountsUnderHundred,
  };
};

export const validatePathway = ({ page }) => {
  const hasStep = !!page?.pathway?.pathway_steps?.filter(step => !step?._destroy)?.length > 0;
  return { hasStep };
};

export const formatConfirmationPage = page => {
  // if we want to redirect then we want to emtpy out the fields related to confirm page and vise versa
  if (page.confirmationRedirectBoolean) {
    page.confirmationParagraph = null;
    page.confirmationCustomLink = null;
    page.confirmationCustomLinkCopy = null;
    page.confirmationFacebookHandle = null;
    page.confirmationTwitterHandle = null;
    page.confirmationVideoEmbed = null;
  } else {
    page.confirmationRedirectUrl = null;
    page.confirmationWithUtms = true;
    page.confirmationWithLeadInfo = false;
    page.replaceUtmWithWrUtm = false;
  }

  return page;
};

export const hasConduitVendors = conduitCandidates => {
  const conduitsHaveVendors = conduitCandidates?.find(conduitCandidate => {
    const _candidate = conduitCandidate.candidate || conduitCandidate.organization;
    return _candidate.available_vendors?.length && !conduitCandidate._destroy;
  });

  return !!conduitsHaveVendors;
};

export const formatExistingPageForServer = page => {
  let pageFormatted = { ...page };
  delete pageFormatted.authorEmail;
  delete pageFormatted.authorCallout;
  return pageFormatted;
};

export const MAX_CAMPAINGS_TO_QUERY = 10;

export const MAX_UPSELL_OPTIONS_TO_QUERY = 10;

export const generateVendorFee = () => {
  return {
    partnershipId: uuidv4(),
    name: '',
    feePercentage: '',
    _isNew: true,
  };
};

export const formatDuplicateTemplateForServer = template => {
  let formattedTemplate = {
    ...template,
    source_code: template.source_code_enabled ? template.source_code : '',
    template_vendor_parameters: template.partnership_templates
      .filter(item => {
        return !(item._destroy && item._isNew);
      })
      .map(item => {
        let formattedPartnership = {
          partnershipId: item.partnershipId,
          feePercentage: parseFloat(item.feePercentage),
        };
        if (item?._destroy) formattedPartnership.destroy = item?._destroy;
        if (!item._isNew) formattedPartnership.id = item.id;
        return { ...formattedPartnership };
      }),
  };
  if (template?.revv_uid === '') {
    delete formattedTemplate.revv_uid;
  }

  delete formattedTemplate.source_code_enabled;
  delete formattedTemplate.partnership_templates;
  delete formattedTemplate.id;
  return formattedTemplate;
};

export const formatPageDuplicateTemplatesForServer = (templates, selectedTemplates) => {
  let selectedIds = new Set(selectedTemplates);
  let formattedTemplates = templates
    .filter(template => {
      return selectedIds.has(template.id);
    })
    .map(item => {
      return {
        template_revv_uid: item.revv_uid,
        custom_name: item.name,
        custom_slug: item.slug,
        custom_source_code: item.source_code,
      };
    });
  return formattedTemplates;
};

export const generateDefaultTemplateData = () => {
  return {
    revv_uid: '',
    name: '',
    slug: '',
    upsell_agency_fees_enabled: false,
    recurring_agency_fees_enabled: false,
    partnership_templates: [generateVendorFee()],
    source_code_enabled: false,
    source_code: '',
  };
};

export const generateTemplateFormData = template => {
  return {
    revv_uid: template?.isEdit ? template.selectedTemplate.revv_uid : '',
    name: template?.isEdit ? template.selectedTemplate.originalName : '',
    slug: template?.isEdit ? template.selectedTemplate.originalSlug : '',
    upsell_agency_fees_enabled: template?.isEdit ? template.selectedTemplate.upsell_agency_fees_enabled : false,
    recurring_agency_fees_enabled: template?.isEdit ? template.selectedTemplate.recurring_agency_fees_enabled : false,
    partnership_templates: template?.isEdit
      ? template.selectedTemplate.partnership_templates.length > 0
        ? template.selectedTemplate.partnership_templates
        : []
      : [],
    source_code_enabled: template?.isEdit ? (template.selectedTemplate.source_code ? true : false) : false,
    source_code: template?.isEdit ? template.selectedTemplate.source_code : '',
  };
};

export const formatCustomName = (pageName, templateName) => {
  let templateString;
  if (/^[0-9a-zA-Z]/.test(templateName)) {
    templateString = ' ' + templateName;
  } else {
    templateString = templateName;
  }
  return `${pageName}${templateString}`;
};
export const formatCustomSlug = (pageSlug, templateSlug) => {
  let templateString;
  if (templateSlug.startsWith(' ')) {
    templateString = '-' + templateSlug.trim();
  } else if (/^[0-9a-zA-Z]/.test(templateSlug)) {
    templateString = '-' + templateSlug;
  } else {
    templateString = templateSlug;
  }
  return `${pageSlug}${templateString}`;
};
