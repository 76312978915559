import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Card, ConfirmModal, ImagePrompt } from 'modules/common';

function LogoImage({ upsellCard, onUpdateEditedUpsell, subtitle, className }) {
  const [showRemoveLogoModal, setShowRemoveLogoModal] = useState(false);

  return (
    <div className={className}>
      <Card.Title subtitle={subtitle}>Add Image</Card.Title>
      <ImagePrompt
        imageUri={
          upsellCard.logo_assigned_image && upsellCard.logo_assigned_image.image
            ? upsellCard.logo_assigned_image.image.thumbnailUrl
            : null
        }
        name="logo_assigned_image"
        selectImageAction={onUpdateEditedUpsell}
        imageType="LOGO"
        disabled={upsellCard._isDisabled}
        removeImageAction={() => setShowRemoveLogoModal(true)}
        isNested
      />

      <ConfirmModal
        show={showRemoveLogoModal}
        title="Remove Logo"
        buttonText="Remove"
        handleClose={() => setShowRemoveLogoModal(false)}
        buttonColor="cancel"
        onSubmit={() => {
          const value = {
            destroy: true,
            imageId: upsellCard.logo_assigned_image?.image?.id,
            id: upsellCard.logo_assigned_image?.id,
          };

          onUpdateEditedUpsell({ target: { name: 'logo_assigned_image', value } });
          setShowRemoveLogoModal(false);
        }}
      >
        Are you sure you want to remove this logo from your upsell?
      </ConfirmModal>
    </div>
  );
}

LogoImage.propTypes = {
  upsellCard: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,

  subtitle: PropTypes.string,
  className: PropTypes.string,
};

LogoImage.defaultProps = {
  className: '',
  subtitle: 'Add an image to your upsell card. The recommended image size is 1041x1041.',
};

export default LogoImage;
